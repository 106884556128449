import { AutoComplete, Menu } from "antd";
import { emzPrimaryColor, sinkEvent } from "../../helpers/utils";
import {
  faBell,
  faBookmark,
  faBookmarkSlash,
  faChartPie,
  faChevronDown,
  faChevronRight,
  faCog,
  faFolder,
  faPersonRunning,
  faSearch,
  faShieldCheck,
  faSlidersV,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EntityConfig } from "../../hooks/useEntity";
import { useTranslation } from "react-i18next";
import useApp from "../../hooks/useApp";
import { useRole } from "../../Auth/useRole";
import Entities from "../../Entities";
import { fetchEGate } from "../../helpers/eGateApi";
import FaIcon, { FakeAntdIcon } from "../../components/FaIcon";
import fls from "../../image/fls.svg";
import useLanguageMenu from "../Topbar/useLanguageMenu";
import { useFavourites } from "../../hooks/userFavourites";

import without from "lodash-es/without";
import union from "lodash-es/union";
import styled from "styled-components";
import { useHotkeys } from "react-hotkeys-hook";
import { BaseSelectRef } from "rc-select";
import { SubMenuType } from "antd/es/menu/interface";
import TopbarCustomers from "../Topbar/TopbarCustomers";

const SearchStyle = styled.div`
  .search-icon {
    svg {
      color: #bfbfbf !important;
    }
    color: #bfbfbf;
  }
`;

const Style = styled(Menu)`
  .ant-menu .ant-menu-item a::before {
    position: initial !important;
  }
  .menu-link-wrapper {
    position: relative;
    flex: 1;
  }
  .ant-menu-title-content {
    .favourite-button {
      visibility: hidden;
    }
  }
  .ant-menu-title-content:hover {
    .favourite-button {
      visibility: visible;
      svg {
        color: #bbe3d0 !important;
      }
    }
  }

  .favourite-button:hover {
    svg {
      color: black !important;
    }
  }
`;
export default function () {
  const location = window.location;
  const { t } = useTranslation();
  const { appData, toggleCollapsed, useGetView, onAfterLogout, toggleMenuOpened, toggleMenuFocused } = useApp();
  const { isMobile } = useGetView();
  const { hasAnyRole } = useRole();

  const isCollapsed: boolean = appData?.collapsed ?? false;
  const menuOpened: boolean = appData?.menuOpened ?? false;

  const [favourites, setFavouritesData] = useFavourites({ enabled: true });

  const selectedCustomer = favourites?.selectedCustomer;
  const sidebarFavourites = favourites?.sidebarFavourites ?? [];
  async function updateFavourites(newSidebarFavourites: string[]) {
    const payload = {
      sidebarFavourites: newSidebarFavourites,
      selectedCustomer,
      id: "",
    };
    setFavouritesData(payload);
    await fetchEGate("PUT", "users/favourites", {
      data: JSON.stringify(payload),
    });
  }

  async function addItemToPreferences(key: string) {
    return updateFavourites(union(sidebarFavourites, [key]));
  }
  async function removeItemFromPreferences(key: string) {
    return updateFavourites(without(sidebarFavourites, key));
  }

  function getMode() {
    if (menuOpened) {
      return "inline";
    }
    return isCollapsed ? "vertical" : "inline";
  }

  const mode = getMode();

  function handleClick() {
    if (isMobile) {
      setTimeout(() => {
        toggleCollapsed();
        toggleMenuOpened();
      }, 100);
    }
  }

  function filterRights(entityConfig: EntityConfig) {
    const roleView = entityConfig.roleView ?? [];
    const canRender = hasAnyRole(...roleView);
    if (!canRender) {
      return null;
    }
    return !!entityConfig;
  }

  const reports: EntityConfig[] = [
    Entities.transaction,
    Entities.issues,
    {
      key: "map",
      baseKey: "map",
      baseRoute: "map",
      endpoint: "",
      roleView: Entities.container.roleView,
      roleAdmin: Entities.container.roleAdmin,
      name: () => t("Container map"),
      singularName: () => t("Container map"),
    },
    Entities.containerEmptying,
    Entities.transactionCharts,
    Entities.issueCharts,
    Entities.endTest,
  ].filter(filterRights);

  const configuration = [
    Entities.container,
    Entities.hardwareUnit,
    Entities.contract,
    Entities.customer,
    Entities.nfcCard,
    Entities.firmware,
    Entities.largeList,
    Entities.regionCode,
    Entities.nfcCardBlacklist,
    Entities.containersAccessGroup,
    Entities.address,
    Entities.product,
    Entities.customerFeatures,
    Entities.hardwareCommands,
    Entities.lockTypeAliases,
  ].filter(filterRights);

  const service = [
    Entities.serviceTask,
    Entities.serviceCalls,
    Entities.serviceAppEvents,
    Entities.technician,
    Entities.serviceTaskCategory,
    Entities.serviceTaskCause,
    Entities.serviceTaskMaterial,
    Entities.serviceTaskSolution,
    Entities.systemOperationLogs,
  ].filter(filterRights);

  const socialServices = [
    Entities.socialServicesRunners,
    Entities.socialServicesNotificationMessages,
    Entities.socialServiceActions,
  ].filter(filterRights);

  const presets = [
    Entities.cardReaderGroup,
    Entities.fractionConfigurationGroup,
    Entities.regionCodeConfigurationGroup,
    Entities.intervalConfigurationGroup,
    Entities.emptyingDetection,
    Entities.mifareDataReadFrame,
    Entities.loRaSettings,
  ].filter(filterRights);

  const fillLevel = [
    Entities.fillContainers,
    Entities.fillLevelSensor,
    Entities.taskList,
    Entities.liftingSystem,
    Entities.truck,
    Entities.truckType,
    Entities.truckBody,
  ].filter(filterRights);

  const security = [Entities.user, Entities.permissionGroup, Entities.devices].filter(filterRights);
  const manage = [Entities.appMessage].filter(filterRights);
  const flows = [Entities.flows].filter(filterRights);

  const mobile = useGetView().isMobile;

  const path = location.pathname.replace("/app/", "");

  const keyMap = { configuration, presets, security, reports, manage, fillLevel, flows, service, socialServices };
  const submenuKey = Object.entries(keyMap).find((i) => i[1].find((entConf) => entConf.baseRoute === path));

  const langMenu = useLanguageMenu();

  function getLabel(i: EntityConfig) {
    const isFav = sidebarFavourites.find((fav) => i.key === fav);
    const showFav = !isCollapsed;

    return (
      <div className={"flex flex-row justify-between align-center"} onClick={() => navigate("/app/" + i.baseRoute)}>
        <div className={"menu-link-wrapper"}>
          <Link to={`/app/${i.baseRoute}`}>{i.name(t)}</Link>
        </div>
        {showFav ? (
          <>
            {isFav ? (
              <FaIcon
                className={"favourite-button"}
                icon={faBookmarkSlash}
                role={"button"}
                size={"lg"}
                title={t("Unpin")}
                onClick={(e) => {
                  sinkEvent(e);
                  removeItemFromPreferences(i.key);
                }}
              />
            ) : (
              <FaIcon
                className={"favourite-button"}
                icon={faBookmark}
                role={"button"}
                size={"lg"}
                title={t("Pin")}
                onClick={(e) => {
                  sinkEvent(e);
                  addItemToPreferences(i.key);
                }}
              />
            )}
          </>
        ) : null}
      </div>
    );
  }
  function canRenderEntity(i: EntityConfig) {
    const roleView = i.roleView ?? [];
    return hasAnyRole(...roleView);
  }
  function convert(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;
    return {
      key: i.baseKey,
      icon: i.icon,
      title: i.name(t),
      label: getLabel(i),
    };
  }
  function convertTopLevel(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;

    return {
      key: i.baseKey,
      icon: i.icon,
      title: i.name(t),
      label: <Link to={`/app/${i.baseRoute}`}>{i.name(t)}</Link>,
    };
  }

  function convertFavourites(i: EntityConfig) {
    if (!canRenderEntity(i)) return null;

    return {
      key: i.baseKey + "_fav",
      icon: i.icon,
      title: i.name(t),
      label: getLabel(i),
    };
  }
  function mobileItem(i: any) {
    if (!mobile) {
      return null;
    }
    return i;
  }
  function convertSub(i: SubMenuType) {
    if (i.children.length === 0) {
      return null;
    }
    return i;
  }

  const [search, setSearch] = useState("");
  const allMenuItems = [
    ...reports,
    ...configuration,
    ...presets,
    ...service,
    ...fillLevel,
    ...security,
    ...manage,
    ...socialServices,
    ...flows,
  ];
  const favouritesItems = allMenuItems.filter((entity) => sidebarFavourites?.includes(entity.key));

  const filteredMenuItems = allMenuItems.filter((entity) => {
    if (search === "") {
      return true;
    }
    return entity.name(t).toLowerCase().includes(search.toLowerCase());
  });

  const menuItems = [
    favouritesItems.length
      ? {
          children: favouritesItems.map(convertFavourites),
          label: t("Bookmarked"),
          key: "favourites",
          icon: <FaIcon icon={faBookmark} size={"lg"} />,
        }
      : undefined,
    convertTopLevel(Entities.dashboard),
    convertTopLevel(Entities.serviceCockpit),
    convertSub({
      children: reports.map(convert),
      label: t("Reports"),
      key: "reports",
      icon: <FaIcon icon={faChartPie} size={"lg"} />,
    }),
    convertSub({
      children: configuration.map(convert),
      label: t("Configuration"),
      key: "configuration",
      icon: <FaIcon icon={faCog} size={"lg"} />,
    }),
    convertSub({
      children: service.map(convert),
      label: t("Service"),
      key: "service",
      icon: <FaIcon icon={faWrench} size={"lg"} />,
    }),
    convertSub({
      children: socialServices.map(convert),
      label: t("Social service"),
      key: "socialServices",
      icon: <FaIcon icon={faPersonRunning} size={"lg"} />,
    }),
    convertSub({
      children: presets.map(convert),
      label: t("Presets"),
      key: "presets",
      icon: <FaIcon icon={faSlidersV} size={"lg"} />,
    }),
    convertSub({
      children: fillLevel.map(convert),
      label: t("Fill level"),
      key: "fillLevel",
      icon: (
        <FakeAntdIcon className={"anticon"}>
          <img alt={"fill level"} className="fak fa-fls fa-lg" height={22} src={fls} width={22} />
        </FakeAntdIcon>
      ),
    }),
    convertTopLevel(Entities.flows),
    convertSub({
      children: security.map(convert),
      label: t("Security"),
      key: "security",
      icon: <FaIcon icon={faShieldCheck} size={"lg"} />,
    }),
    convertSub({
      children: manage.map(convert),
      label: t("Manage"),
      key: "manage",
      icon: <FaIcon icon={faBell} size={"lg"} />,
    }),
    mobileItem({
      label: <Link to={"/app/profile"}>{t("Profile")}</Link>,
      key: "profile",
    }),

    mobileItem({
      label: (
        <div
          onClick={async () => {
            await fetchEGate("GET", "authorize/logout");
            await onAfterLogout();
          }}
        >
          {t("Logout")}
        </div>
      ),
      key: "logout",
    }),
    mobileItem(langMenu),
  ].filter((i) => i);

  const navigate = useNavigate();
  const ref = useRef<BaseSelectRef>(null);

  useHotkeys("shift + s", () => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  });
  return (
    <>
      <SearchStyle
        className={"mt-1"}
        hidden={isMobile ? false : isCollapsed}
        style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
      >
        <div>{isMobile ? <TopbarCustomers /> : null}</div>

        <AutoComplete
          ref={ref}
          className={"w100 mt-2"}
          defaultActiveFirstOption
          options={filteredMenuItems.map((i: EntityConfig) => ({
            value: i.key,
            label: i.name(t),
            baseRoute: i.baseRoute,
          }))}
          placeholder={
            <div className={"flex justify-between"}>
              <div>
                <FaIcon className={"search-icon mr1"} icon={faSearch} size={"lg"} />
                <span>{t("Search...")}</span>
              </div>
              <div>{t("shift + s")}</div>
            </div>
          }
          value={search}
          onChange={(v: any, option: any) => {
            setSearch("");

            if (!option?.baseRoute) {
              return;
            }
            navigate("/app/" + option?.baseRoute);
          }}
          onSearch={setSearch}
        />
      </SearchStyle>

      <Style
        className={"side-menu"}
        contextMenu={undefined}
        defaultOpenKeys={[submenuKey?.[0] as string]}
        defaultSelectedKeys={[path]}
        expandIcon={(e: any) => {
          if (!menuOpened && isCollapsed) {
            return null;
          }

          return (
            <span className={"ant-menu-submenu-expand-icon"}>
              <FaIcon color={e.isOpen ? emzPrimaryColor : "#999999"} icon={e.isOpen ? faChevronDown : faChevronRight} />
            </span>
          );
        }}
        forceSubMenuRender
        items={menuItems}
        mode={mode}
        multiple={false}
        theme="light"
        onBlur={() => {
          toggleMenuFocused(false);
        }}
        onClick={handleClick}
        onFocus={() => toggleMenuFocused(true)}
      />
    </>
  );
}
