import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import i18nEng from "./translations/en/translation.json";
import i18nDe from "./translations/de/translation.json";
import i18nIt from "./translations/it/translation.json";
import i18nPt from "./translations/pt/translation.json";
import i18nFr from "./translations/fr/translation.json";
import i18nDa from "./translations/da/translation.json";
import i18nHr from "./translations/hr/translation.json";

// the translations
export const resources = {
  en: { translation: i18nEng },
  de: { translation: i18nDe },
  it: { translation: i18nIt },
  pt: { translation: i18nPt },
  fr: { translation: i18nFr },
  da: { translation: i18nDa },
  hr: { translation: i18nHr },
};
export function resolveLanguage(lang: keyof typeof resources) {
  if (resources?.[lang]) {
    return lang;
  }
  return "en";
}
void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: "en",
      keySeparator: false, // we do not use keys in form messages.welcome
      returnEmptyString: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    (error, t) => {
      // Only for the extractor
      t("en");
      t("de");
      t("it");
      t("pt");
      t("fr");
      t("da");
      t("hr");
      t("We are sorry but eGate application does not support Internet Explorer.");
      t(
        "Internet Explorer is no longer supported and maintained by Microsoft, does not adhere to modern web standards and may contain serious security vulnerabilities."
      );
      t("Please consider using a more current and safer browser.");
    }
  );

export default i18n;
