import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";
import { faAnalytics } from "@fortawesome/pro-light-svg-icons";
import FaIcon from "../components/FaIcon";
import React from "react";

const conf: EntityConfig = {
  ...entityFactory("dashboard"),
  endpoint: "dashboard",
  initialData: {
    appUsersTotal: 0,
    issuesToday: 0,
    issuesTotal: 0,
    transactionConflictsTotal: 0,
    transactionsToday: 0,
    transactionsTotal: 0,
    unknownContainerIssuesToday: 0,
    unknownContainerIssuesTotal: 0,
    transactionsUnconfirmed: 0,
  },
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN],
  roleAdmin: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN],
  baseRoute: "dashboard",
  singularName: (t) => t("Dashboard"),
  name: (t) => t("Dashboard"),
  disableCache: true,
  icon: <FaIcon icon={faAnalytics} size={"lg"} />,
};

export default conf;
