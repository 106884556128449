import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("social-service-notification-messages"),
  endpoint: "social-service-notification-messages",
  roleView: [ROLE.SC_TASK_CATEGORY_VIEW, ROLE.SC_TASK_CATEGORY_ADMIN],
  roleAdmin: [ROLE.SC_TASK_CATEGORY_ADMIN],
  singularName: (t) => t("Notification message"),
  name: (t) => t("Notification messages"),
  syncCustomerFilter: true,
  dependencies: ["service-task"],
  sendCulture: true,
};
export default conf;
