import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { rangeFilterUTC } from "../helpers/utils";

const conf: EntityConfig = {
  ...entityFactory("service-task"),
  endpoint: "service-task",
  roleView: [ROLE.SC_TASK_VIEW, ROLE.SC_TASK_ADMIN],
  roleAdmin: [ROLE.SC_TASK_ADMIN],
  baseRoute: "service-tasks",
  singularName: (t) => t("Service task"),
  name: (t) => t("Service tasks"),
  syncFilterWithURL: true,
  sendCulture: true,
  initialFilter: {},
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
};

export default conf;
