/**
 * Security roles
 */
import useApp from "../hooks/useApp";
import { AuthorizeWebResponse } from "../eGate-API";

export const ROLE = Object.freeze({
  APP_USER: "APP_USER",

  CONTAINER_ADMIN: "CONTAINER_ADMIN",
  CONTAINER_VIEW: "CONTAINER_VIEW",

  APP_SERVICE_USER: "APP_SERVICE_USER",

  CONTRACT_ADMIN: "CONTRACT_ADMIN",
  CONTRACT_VIEW: "CONTRACT_VIEW",

  CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
  CUSTOMER_VIEW: "CUSTOMER_VIEW",

  FEATURE_ADMIN: "FEATURE_ADMIN",
  FEATURE_VIEW: "FEATURE_VIEW",
  CUST_EULA_ADMIN: "CUST_EULA_ADMIN",

  IMAGE_ADMIN: "IMAGE_ADMIN",

  ISSUE_ADMIN: "ISSUE_ADMIN",
  ISSUE_VIEW: "ISSUE_VIEW",

  PERMISSION_GROUP_ADMIN: "PERMISSION_GROUP_ADMIN",
  PERMISSION_GROUP_VIEW: "PERMISSION_GROUP_VIEW",

  REGION_CODE_ADMIN: "REGION_CODE_ADMIN",
  REGION_CODE_VIEW: "REGION_CODE_VIEW",

  APP_MESSAGE_ADMIN: "APP_MESSAGE_ADMIN",
  APP_MESSAGE_VIEW: "APP_MESSAGE_VIEW",

  TRANSACTION_VIEW: "TRANSACTION_VIEW",

  USER_ADMIN: "USER_ADMIN",
  USER_VIEW: "USER_VIEW",

  ENDTEST_RUN: "ENDTEST_RUN",
  ENDTEST_VIEW: "ENDTEST_VIEW",

  FILL_SENSOR_VIEW: "FILL_SENSOR_VIEW",
  FILL_SENSOR_ADMIN: "FILL_SENSOR_ADMIN",

  PRODUCT_ADMIN: "PRODUCT_ADMIN",
  PRODUCT_VIEW: "PRODUCT_VIEW",

  TRUCK_ADMIN: "TRUCK_ADMIN",
  TRUCK_VIEW: "TRUCK_VIEW",

  LOCK_TYPE_ALIAS_VIEW: "LOCK_TYPE_ALIAS_VIEW",
  LOCK_TYPE_ALIAS_FORM: "LOCK_TYPE_ALIAS_FORM",

  SC_TASK_CATEGORY_VIEW: "SC_TASK_CATEGORY_VIEW",
  SC_TASK_CATEGORY_ADMIN: "SC_TASK_CATEGORY_ADMIN",
  SC_APP_EVENT_VIEW: "SC_APP_EVENT_VIEW",
  SC_APP_EVENT_ADMIN: "SC_APP_EVENT_ADMIN",
  SC_TASK_VIEW: "SC_TASK_VIEW",
  SC_TASK_ADMIN: "SC_TASK_ADMIN",

  FIRMWARE_ADMIN: "FIRMWARE_ADMIN",
  FIRMWARE_VIEW: "FIRMWARE_VIEW",

  SC_ADMIN: "SC_ADMIN",
  SC_VIEW: "SC_VIEW",

  EMZ_DEVELOPER: "EMZ_DEVELOPER",

  AUTO_READOUT_VIEW: "AUTO_READOUT_VIEW",

  HARDWARE_UNIT_VIEW: "HARDWARE_UNIT_VIEW",
  HARDWARE_UNIT_ADMIN: "HARDWARE_UNIT_ADMIN",

  LARGE_LIST_VIEW: "LARGE_LIST_VIEW",
  LARGE_LIST_ADMIN: "LARGE_LIST_ADMIN",
});

export function useRole() {
  const { appData } = useApp();
  const auth: AuthorizeWebResponse | undefined = appData?.auth;

  /**
   * Checks if role is presetn
   * @param role
   */
  const hasRole = (role: string): boolean => {
    return auth?.roles?.includes(role) ?? false;
  };

  /**
   * Creates selector that gets whether the logged-in user
   * has any of the listed security roles
   *
   * @param roles - Roles to check
   */
  const hasAnyRole = (...roles: string[]) => roles.some((r) => auth?.roles?.includes(r));

  /**
   * React hook to check if logged-in user has any of the listed security roles
   *
   * @param roles - Roles to check
   */
  const useAnyRole = (...roles: string[]) => hasAnyRole(...roles);

  return {
    hasRole,
    hasAnyRole,
    useAnyRole,
  };
}
