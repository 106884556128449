import { fetchEGate } from "../helpers/eGateApi";
import { Favourites } from "./useApp";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function useFavourites({ enabled = true }): [Favourites | undefined, (payload: any) => void] {
  const queryClient = useQueryClient();
  const favourites = useQuery({
    queryKey: ["favourites"],
    queryFn: () =>
      fetchEGate("GET", "users/favourites")
        .then((r) => r.text())
        .then(JSON.parse)
        .then(JSON.parse),
    retry: false,
    enabled: enabled,
  });

  function setFavouritesData(payload: any) {
    queryClient.setQueryData(["favourites"], payload);
  }

  return [favourites.data, setFavouritesData];
}
