import { Button, Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useServerInfo from "./hooks/useServerInfo";

export default function (props: { developmentError?: string | undefined; sentryId?: string | undefined }) {
  const { t } = useTranslation();
  const { data } = useServerInfo();
  const deployment = data?.deployment;
  const isProd = deployment === "production";

  const showDevMessage = !isProd;
  return (
    <Result
      extra={[
        <a key="home" href={"/app/dashboard"}>
          <Button key="home" type="primary">
            {t("Return to dashboard")}
          </Button>
        </a>,
      ]}
      status="error"
      subTitle={t("We are very sorry")}
      title={t("App has crashed")}
    >
      {showDevMessage && props.developmentError && (
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "red" }}>ERROR INFO FOR TEST AND DEVELOPMENT ENVIROMENT ONLY</h2>
          <h3>Error message: </h3> {JSON.stringify(props.developmentError)}
          <h3>Sentry ID:</h3>
          <a
            href={`https://sentry.io/organizations/emz-smart-solutions/?query=${props.sentryId?.replace("$", "")}`}
            rel="noopener noreferrer"
            target={"_blank"}
          >
            ${props.sentryId}
          </a>
        </div>
      )}
    </Result>
  );
}
